.search-root{
    padding-bottom: 5px;
    box-shadow: 0 5px 5px -5px #333;;
    border-radius: 4px;
    overflow: visible;
    background-color: #f2f2ea;
}

.search-root2 {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 3px;
}

.MuiAutocomplete-inputLabel {
    transform: translateY(-30px);
  }