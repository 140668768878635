.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .show-more-text-mobile{
    font-size: 13px;
    font-family: Inter;
  }