@keyframes pulse {
    0% {
      transform: scale(0);
      opacity: 0.0;
    }
    50% {
      opacity: 1.0;
    }
    100% {
      transform: scale(1.5);
      opacity: 0.0;
    }
  }
  
  .pulsating-marker {
    width: 20px;
    height: 20px;
    background-color: rgb(37, 52, 0);
    border-radius: 50%;
    animation: pulse 1s infinite;
  }
