.CloseButtonDetailedview{
    position: absolute;
    left: 85%;
    top: -7%;
    background-color: lightgray;
    color: gray;
}

.showmoretext-detailedview {
    font-family: "Inter";
    font-size: 0.9rem;
    padding-top: 5px;
    font-weight: 400;
}