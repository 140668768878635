.leaflet-container {
  width: 100%;
  height: 100%;
}

#top-right-image {
  position: absolute;
  top: 0px;
  right: 55px;
  width: 150px; /* change this to adjust the size of the image */
  height: 80px; /* change this to adjust the size of the image */
  z-index: 9999; /* make sure the image is above other elements */
}

.collapse-from-right {
  position: relative;
  overflow: hidden;
  height: auto;
  width: 100%;
  transition: width 0.3s ease-out;
}

.collapse-from-right > div {
  transition: margin-right 0.3s ease-out;
  margin-right: -100%;
}

.MuiIconButton-root.Mui-expanded .MuiIconButton-label .MuiSvgIcon-root {
  background-color: white;
}