 
  .custom-popup-content h4 {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
  }
  
  .custom-popup-content p {
    margin: 0;
    font-size: 12px;
  }
  
  .leaflet-popup-tip-container {
    display: none;
  }

  .custom-popup-content {
    padding: -10px;
  }
