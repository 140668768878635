.showmoretext-detailed-mobile {
    font-size: 12px;
    font-family: Inter;
}

.smallmobile_title {
    display: '-webkit-box';
    -webkit-box-orient: 'vertical';
    -webkit-line-clamp: 2;
    overflow: 'hidden';
    text-overflow: 'ellipsis';
}